import { Grid } from "@mui/material"
import { Link, Navigate } from "react-router-dom"
import { ImgUrl } from "../func"
import { useSearchParams, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CommonHeader,HomeIcon } from "../header"

export default function Member(pp) {

    const { id } = useParams()
    const member = members[id]

    return (
        <>
            <CommonHeader />
            <div className="page-container">
                {/* <p className="center mt-5"><Link to="/"><img src={ImgUrl("home.png")} width="32" className="home-icon" alt="home" /></Link></p> */}

                <h1 className="center">{member.name}</h1>

                <Grid container justifyContent="center" rowSpacing={3} columnSpacing={0} style={{ maxWidth: "8300px", margin: "auto" }}>
                    <Grid item md={12}>
                        <div className="center">
                            <img src={ImgUrl(id + ".jpg")} alt="" className="member circle" />
                        </div>
                    </Grid>
                </Grid>

                <SNSi member={member} />

                <div className="fs-3 mt-4">
                    {member.heading}
                </div>

                <div className="fs-5 mt-4" dangerouslySetInnerHTML={{ __html: member.content }}>
                </div>
                <div className="mt-4">
                    {
                        member.youtube.length > 0 && member.youtube.map(x => <iframe key={x} width="100%" style={{ marginBottom: "30px" }} height="315" src={"https://www.youtube.com/embed/" + x} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>)
                    }
                </div>


                <HomeIcon />
            </div>
        </>
    )

}

const SNSi = (pp) => {

    const items = []
    for (let key in pp.member.sns) {
        items.push(<li class="list-group-item anim-3">
            <a href={pp.member.sns[key]} target="_blank"><img src={"../img/svg/"+key+".svg"} width="32" alt="" /></a>
        </li>)
    }

    if (pp.member.sns.length == 0) return null
    return (
        <div class="sns">
            <ul class="list-group list-group-horizontal justify-content-center mt-4">
                {items}
            </ul>
        </div>
    )
}

export const members =
{
    "saika": {
        "name": "SAIKA",
        "heading": "",
        "content": `
        <p>学生時代からITの時代変化に興味を持ち、卒業後すぐに上京。
        大手ベンダー会社でBtoCを学んだ後にスタートアップのベンチャーIT企業に参画。</p>
        
        <p>アーリー期からミドル期の拠点・事業拡大を経験した後、
        大手ゲームパブリッシャー企業や外資系ITベンチャー企業で企画ディレクションや
        サポートマネジメントSVのほか、採用や教育などにも従事。
        合計100名以上のIT未経験メンバーを会社のキーマンに育て上げる。</p>
        
        <p>その後、ゲーム企業で共に働いた「TATSU」と再会したことで
        クリエイターチーム「Millmol」をスタートさせる。</p>
        `,
        "youtube": [],
        "sns": {
            "twitter": "https://twitter.com/SaikaMillmol",
            "facebook":"https://www.facebook.com/ayaki.chikaishi",
            "instagram":"https://www.instagram.com/saikaxmxmxm/"
        }
    },
    "tatsu": {
        "name": "TATSU",
        "heading": "ゲームシナリオライター/劇作・演出家/漫画原作者",
        "content": `
        <p>高校在学中に筆を執り、SEO、セールス、コピー等
        多岐にわたりフリーのライターとして活動。
        同時に映像演出や舞台演出、音響演出といった活動も行う。</p>
        
        <p>その後、執筆を「物語」に絞り、舞台演劇や同人ゲーム等のシナリオを制作。
        2013年には、自身がプロデュースし企画・脚本・演出を務める。</p>
        
        <p>2014年からは有名ゲーム制作会社へイベント演出プランナーとして参画。
        更に様々な大手業界でコンシューマ/ソーシャルゲーム/ブラウザゲーム等、
        複数のジャンルでシナリオ・音響ディレクター、ライターとして活動。</p>
        
        <p>2019年には漫画の原作者として活動を開始。
        ジャンプコミックにて電子単行本化。</p>
        
        <p>ライター、シナリオチーム統括として実績と経験を持ったクリエイター。</p>
        `,
        "youtube": [],
        "sns": {
            "twitter": "https://twitter.com/tatu_asma"
        }
    },
    "iroha": {
        "name": "IROHA",
        "heading": "フルスタックエンジニア/ゲームデザイナー/マルチクリエイター",
        "content": `
        <p>物心がつく頃よりエンジニアスキルを学び、15を超えるプログラミング言語や、その他多くのEngineerツールを習得。</p>

        <p>その技術を活かすべく、若くして会社を設立し、VRやMRを使用した最新技術を中心に開発をしてきた。
        その結果、大手企業や行政からの仕事を受け持ち、数多くの部門で多大な評価を受けるなど、多種多様な顧客との信頼関係を掴んできた。</p>
        
        
        <p>また、e-sportsが普及する前時代に世界大会代表選手として腕を振るい、
        チームリーダーとして世界2位へ導くなど、ゲームの腕前も上位ランクであり、
        プレイヤーとしてもクリエイターとしてもプロの顔を持つ。</p>
        
        <p>現在はMillmolの開発責任者として組織を支えながら企画の本質を読み取り、
        豊富なエンジニアスキルを駆使して日本に新たな文化を作り続けている技術の先駆者。</p>
        `,
        "youtube": [],
        "sns": {
            "twitter": "https://twitter.com/iroha_millmol"
        }
    }
}
