import { Link } from "react-router-dom"
import { ImgUrl } from "../func"
export default function Policies() {
    return (

        <div className="page-container">
            <p className="center mt-5"><Link to="/"><img src={ImgUrl("home.png")} width="32" className="home-icon" alt="home" /></Link></p>

            <h1>プライバシーポリシー</h1>

            Millmol(以下、「当社」といいます。)は、個人情報の重要性を認識し、<br />
            個人情報を保護することが社会的責務であると考え、個人情報に関する法令及び<br />
            社内規程等を遵守し、当社で取扱う個人情報の取得、利用、管理を適正に行います。<br />
            <br />
            また、本プライバシーポリシーの適応範囲は、当社が行う各種サービスにおいて、<br />
            ご本人様の個人情報もしくはそれに準ずる情報を取り扱う際に、<br />
            当社が遵守する方針を示したものです。<br />
            <br />
            第1条（個人情報）<br />
            「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、<br />
            生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、<br />
            電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、<br />
            指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から<br />
            特定の個人を識別できる情報（個人識別情報）を指します。<br />
            <br />
            第2条（個人情報の収集方法）<br />
            当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、<br />
            メールアドレスなどの個人情報をお尋ねすることがあります。<br />
            <br />
            また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。<br />
            以下、｢提携先｣といいます。）などから収集することがあります。<br />
            <br />
            第3条（個人情報を収集・利用する目的）<br />
            当社が個人情報を収集・利用する目的は、以下のとおりです。<br />
            <br />
            ・当社サービスの提供・運営のため<br />
            ・ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）<br />
            ・ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する<br />
            ・他のサービスの案内のメールを送付するためメンテナンス、重要なお知らせなど<br />
            ・必要に応じたご連絡のため利用規約に違反したユーザーや、不正・不当な目的で<br />
            ・サービスを利用しようとするユーザーの特定をし、ご利用をお断りするため<br />
            ・ユーザーご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため<br />
            ・有料サービスにおいてユーザーに利用料金を請求するため上記の利用目的に付随する目的<br />
            <br />
            第4条（利用目的の変更）<br />
            当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、<br />
            個人情報の利用目的を変更するものとします。<br />
            <br />
            利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、<br />
            ユーザーに通知し、または本ウェブサイト上に公表するものとします。<br />
            <br />
            第5条（個人情報の第三者提供）<br />
            当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、<br />
            第三者に個人情報を提供することはありません。<br />
            <br />
            1）ご本人様の同意がある場合<br />
            2）法令に基づく場合<br />
            3）人の生命、身体又は財産の保護のために必要がある場合であって、<br />
            ご本人様の同意を得ることが困難な場合<br />
            4）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、<br />
            ご本人様の同意を得ることが困難な場合<br />
            5）国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を<br />
            遂行することに対して協力する必要がある場合であって、<br />
            ご本人様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合<br />
            <br />
            第6条（個人情報の開示）<br />
            当社は、本人から個人情報の開示を求められたときは、<br />
            本人に対し、遅滞なくこれを開示します。<br />
            ただし、開示することにより次のいずれかに該当する場合は、<br />
            その全部または一部を開示しないこともあり、開示しない決定をした場合には、<br />
            その旨を遅滞なく通知します。なお、個人情報の開示に際しては、<br />
            1件あたり1,000円の手数料を申し受けます。<br />
            <br />
            本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
            当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br />
            その他法令に違反することとなる場合<br />
            前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。<br />
            <br />
            第7条（個人情報の訂正および削除）<br />
            ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、<br />
            当社が定める手続きにより、当社に対して個人情報の訂正、<br />
            追加または削除（以下、「訂正等」といいます。）を請求することができます。<br />
            <br />
            当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した<br />
            場合には、遅滞なく、当該個人情報の訂正等を行うものとします。<br />
            <br />
            当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の<br />
            決定をしたときは遅滞なく、これをユーザーに通知します。<br />
            <br />
            第8条（個人情報の利用停止等）<br />
            当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、<br />
            または不正の手段により取得されたものであるという理由により、<br />
            その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。<br />
            <br />
            前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、<br />
            遅滞なく、当該個人情報の利用停止等を行います。<br />
            <br />
            当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の<br />
            決定をしたときは、遅滞なく、これをユーザーに通知します。<br />
            <br />
            前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき<br />
            措置をとれる場合は、この代替策を講じるものとします。<br />
            <br />
            第9条（プライバシーポリシーの変更）<br />
            本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、<br />
            ユーザーに通知することなく、変更することができるものとします。<br />
            当社が別途定める場合を除いて、変更後のプライバシーポリシーは、<br />
            本ウェブサイトに掲載したときから効力を生じるものとします。<br />
            <br />
            第10条（お問い合わせ窓口）<br />
            本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。<br />
            <br />
            社名：Millmol<br />
            Eメールアドレス：info@millmol.com<br />
            <br />
            2023年12月08日<br />
            Millmol代表　Saika<br />
            <p className="center mt-5"><Link to="/"><img src={ImgUrl("home.png")} width="32" className="home-icon" alt="home" /></Link></p>

        </div>
    )
}