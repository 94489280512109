import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import OldHome from './OldHome';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Policies from './pages/privacy-policies';
import Footer from './footer';
import News from './News'
import Page from './Page'
import Home from './Home'
import Member from './pages/member';
import Header from './header'
import * as my from './myfunc'



ReactDOM.render(
  <>
    <Header />
    <BrowserRouter basename={process.env.REACT_APP_BASEURL}>
      <my.ScrollTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sizetest" element={<OldHome img="size.png" />} />
        <Route path="/privacy-policies" element={<Policies />} />
        <Route exact path="/member/:id" element={<Member />} />
        <Route path="/news" element={<News />} />
        <Route path="/page/:id" element={<Page />} />
        <Route path="/news3" element={<Home />} />
        {/* TODO: make 404 */}
        <Route path="*" status={404} element={<p className='center fs-1 not-found'>404 Page Not Found.</p>} />
      </Routes>
    </BrowserRouter>
    <Footer />
  </>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

