import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import SanitizedHTML from "react-sanitized-html"
import { Link } from "react-router-dom"
import { ImgUrl } from "./func"
import { CommonHeader } from "./header"

export const News = (pp) => {


    const [content, setContent] = useState(null);
    const [opValue, setOpValue] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();
    const contentElement = document.createElement('div')

    useEffect(() => {
        fetch("https://millmol.com/wp/wp-json/wp/v2/posts?include[]=" + searchParams.get("p"))
            .then((res) => {
                if (res.ok)
                    return res.json()
            })
            .then((res) => {
                setContent(res[0])
                setOpValue(1)
                window.scroll({ top: 0, behavior: "instant" })
            })
            .catch((e) => {
                console.log(e)
            })
        return () => { window.scroll({ top: 0, behavior: "instant" }) }
    }, [])

    return (
        <>
        <CommonHeader />
        <div className="page-container">
            {content == null && (
                <div className="semipolar-spinner m-auto my-4">
                    <div className="ring"></div>
                    <div className="ring"></div>
                    <div className="ring"></div>
                    <div className="ring"></div>
                    <div className="ring"></div>
                </div>
            )}
            <div className="page-content" style={{ opacity: opValue }}>
                <span>{content != null && content.date.substring(0, 10)}</span>
                <h2>{content != null && content.title.rendered}</h2>
                <hr className="my-5" />
                {content != null && <SanitizedHTML
                    allowedTags={['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
                        'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
                        'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img']}
                    html={content.content.rendered} />}
                <p className="center mt-5"><Link to="/"><img src={ImgUrl("home.png")} width="32" className="home-icon" /></Link></p>
            </div>
        </div>
        </>
    )
}

export default News