import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import './App.css';

function OldHome(pp) {
  const [searchParams] = useSearchParams();



  return (
    <>
      <div className='container-fluid'>
        <div className="row">
          <div className='d-none d-lg-block col-lg-4'>
            <div className='fs-1 p-5 text-dark center' style={{ fontFamily: 'Spirax, Cormorant Garamond' }}>
              <img src="./img/millmol_logo.png" alt="" width="100%" style={{ maxWidth: 200 }} /><br />
              Millmol
            </div>
            <Menu />
          </div>
          <div className='fs-1 col-lg-8 d-flex align-items-center justify-content-center' style={{ minHeight: 300, overflow: 'hidden' }}>
            <img src={"./img/" + pp.img} alt="" className='' height="720" />
            {/* キャラクターを入れることは確定<br />
            なんのイラスト？誰に頼む？ */}
          </div>
        </div>
      </div>
      <About />
      <News />
      <Contact />
    </>
  );
}

const Menu = () => {
  return (<ul className='main-menu list-group fs-5 text-decoration-none'>
    <a href="#about"><MenuItem title="ABOUT" /></a>
    <a href="#member"><MenuItem title="MEMBER" /></a>
    <a href="#news"><MenuItem title="NEWS" /></a>
    <a href="#contact"><MenuItem title="CONTACT" /></a>
  </ul>)
}

const Contact = () => {
  return (
    <div className='news mt-5 container' id="contact">
      <h1>Contact</h1>
      <form>
        <div className="mb-3">
          <label for="exampleInputPassword1" className="form-label">Name</label>
          <input type="text" className="form-control" id="exampleInputPassword1" />
        </div>
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">Email</label>
          <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
        </div>
        <div>
          <label for="exampleInputEmail1" className="form-label">Message</label>
          <textarea cols="30" rows="10" className='form-control'></textarea>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" />
          <label className="form-check-label" for="exampleCheck1">Check me out</label>
        </div>
        <button type="submit" className="btn btn-primary p-2" style={{ "width": 150 }}>送信</button>
      </form>
    </div>
  )
}

const About = () => {
  return (
    <div className='news mt-5 container' id="about">
      <h1 className='mb-5'>About</h1>
      <div className='row'>
        <div className='col-3 right'>
          <img src="./img/millmol_logo.png" alt="" width="100%" style={{ maxWidth: 200 }} className='' />
        </div>
        <div className='col-9 fs-4 -d-flex -align-items-center -justify-content-center' style={{}}>
          <p>Millmol　について</p>
          <p>
            Millmolとは、我々が掲げる「Vision」から「世界」「境界」「創造」の
            3つのキーワードに分け、様々な言語から生み出した造語です。

          </p>
          <p>
            クリエイターの皆さんには、自身の創作活動を思うままに行っていただきたいのですが、
            多くの方は身体面や食生活など、何かを犠牲にしていることが多くあります。
            その結果、活動が行えなくなってしまったという声も後を絶ちません。

          </p>
          <p>
            そのような状況を回避し、心身共に健康的な状態で活動へ専念できるように、
            我々は皆さんのサポートを行いたいと考えています。
          </p>
          <p>どんな方でも、クリエイターとしての夢や思いを諦めて欲しくない。
            Millmolと共に、これから始まる新たな時代のコンテンツを考え、生み出し、
            まだ見ぬクリエイターに繋いでいく。
          </p>
          <p>そんな想いから立ち上がった、クリエイター集団です。</p>
        </div>
      </div>
    </div>
  )
}

const MenuItem = (p) => {
  return (
    <li className='list-group-item p-4' style={{ fontFamily: 'Cormorant Garamond' }}>{p.title}</li>
  )
}

const News = () => {
  const [newsJson, setNewsJson] = useState("")
  const previewLength = 80
  useEffect(() => {
    fetch("https://millmol.com/wp/wp-json/wp/v2/posts?orderby=date&order=desc")
      .then((res) => {
        if (res.ok)
          return res.json()
      })
      .then((res) => {
        setNewsJson(res.map((news) => {

          let content = news.content.rendered.replace(/<[^>]*>/g, '').substring(0, previewLength)
          return (
            <Link to={"/news?p=" + news.id} id={news.id} key={news.id}>
              <div className="list-group-item list-group-item-action flex-column align-items-start">
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">
                    <span className='fs-6 date'>{news.date.substring(0, 10)}</span>
                    <span> {news.title.rendered}</span>
                  </h5>
                </div>
                <small></small>
              </div>
            </Link>
          )
        }))
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])


  return (
    <div className='news mt-5 container' id="news">
      <h1>News</h1>
      <div className="list-group">
        {newsJson}
      </div>
    </div>
  )
}

export default OldHome;
