import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import SanitizedHTML from "react-sanitized-html"
import { Link } from "react-router-dom"
import { ImgUrl } from "./func"
import { CommonHeader, HomeIcon, ContactButton } from "./header"
import WOW from 'wowjs'
import 'animate.css/animate.min.css';


export const Page = (pp) => {


    const [content, setContent] = useState(null);
    const [opValue, setOpValue] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();
    const contentElement = document.createElement('div')


    useEffect(() => {
        new WOW.WOW().init()
    }, [])

    const style = {
        background: 'linear-gradient(to right,#1294b3 65%,white)',
        WebkitBackgroundClip: 'text',
        fontWeight: 'bold',
        WebkitTextFillColor: 'transparent',
        fontSize: '4em',
        marginBottom: '1em'
    }

    const spanStyle = {
        fontWeight: 'bold',
        color: '#777'
    }

    //TODO: 今は直にかいてるけど、ページ増えたらちゃんとデータから読み込むようにしようね。
    return (
        <>
            <CommonHeader />

            <div className="page-container">
                <h1 className="page-header wow fadeInDown" style={style}>Millmolは、3つの事業を行っているクリエイターチームです。</h1>
                <p className="animated fadeIn fs-4 desc gray delay-1s">
                    サウンド、シナリオ、3Dモデリング、XR分野、コンサルティング、カスタマーサポートなど、 多種多様な技術を持つクリエイター達が所属しています。</p>

                <p className="animated fadeIn delay-1-5s mt-5">
                    <span style={spanStyle}>01</span><h3>XR（AR/VR/MR）コンテンツ・システム開発</h3>
                    実績：現在開発中、2024年夏リリース予定
                    <hr />
                </p>
                <p className="animated fadeIn delay-2s">
                    <span style={spanStyle}>02</span><h3>自社IP制作・開発</h3>
                    実績：【非公開】企業マスコットキャラクターの発案
                    <hr />
                </p>
                <p className="animated fadeIn delay-2-5s">
                    <span style={spanStyle}>03</span><h3>クリエイター教育・支援</h3>
                    実績1：エンジニア未経験者にPython言語を教育。<br />
                    スクレイピングができるようになるまで支援いたしました。<br />
                    期間：4か月<br /><br />

                    実績2：エンジニア経験者にReact、PHP、Word Pressを教育。<br />
                    エンジニアとしての独立支援も行いました。<br />
                    期間：1年2か月<br /><br />

                    実績3：「学校法人滋慶学園 東京スクールオブミュージック＆ダンス専門学校」のオンラインワークショップに音楽理論講師として登壇しました。<br />
                    登壇数：3回<br />
                    期間　：1年2か月<br /><br />

                    実績4：「ジャックサロンワークアカデミー」ホームページ、ロゴ、名刺、POP制作<br />
                    <a href="https://barber-jack.com" target="_blank">https://barber-jack.com</a><br />
                    <a href="https://academy.barber-jack.com" target="_blank">https://academy.barber-jack.com</a>
                    <br />
                    <br />

                    実績5：教育支援団体のWebサイト制作（WebGLによるWebアプリケーション制作）
                    
                    <hr />
                </p>
                <p>
                    <span style={spanStyle}>その他の実績</span><br />
                    実績：ChatGPTを搭載したDiscord bot開発
                    <hr/>
                </p>

                <p className="animated fadeIn">
                    業務委託や制作受注も行っておりますので、 お気軽にご相談いただけますと幸いです。
                </p>
                <ContactButton />
            </div>



            <HomeIcon />
        </>
    )
}

export default Page