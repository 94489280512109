import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <div className='footer mt-5 bg-dark text-white p-5 container-fluid'>
                <div className='row'>
                    <div className='col-12 center'>

                        ©<span>
                            Millmol
                        </span> 2023 -
                    </div>
                    <div className='col-12 center fs-6 mt-4'>
                        <a href={process.env.REACT_APP_BASEURL + "privacy-policies"} className="text-white "><u>プライバシーポリシー</u></a>
                    </div>
                </div>
            </div>
        </>
    )
}