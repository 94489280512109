const SNS = (pp) => {
    return (

        <div class="sns">
            <ul class="list-group list-group-horizontal justify-content-center mt-6">
                <li class="list-group-item anim-3">
                    <a href="https://twitter.com/Millmol_JP" target="_blank"><img src="./img/svg/twitter.svg" width="32" alt="" /></a>
                </li>
                <li class="list-group-item anim-3">
                    <a href="https://www.facebook.com/profile.php?id=100081567350563" target="_blank"><img src="./img/svg/facebook.svg" width="32" alt="" /></a>
                </li>
                <li class="list-group-item anim-3">
                    <a href="https://www.instagram.com/millmol_jp/" target="_blank"><img src="./img/svg/instagram.svg" width="32" alt="" /></a>
                </li>
                <li class="list-group-item anim-3">
                    <a href="https://www.youtube.com/channel/UC0d1M_0PHenqmXMqumQhRBg/featured" target="_blank"><img src="./img/svg/youtube_s.svg" width="32" alt="" /></a>
                </li>
            </ul>
        </div>
    )
}

export default SNS