import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollTop() {
    const { hash } = useLocation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scroll({top:0,behavior:"instant"});
        if(hash && hash.match(/^#/)){
            document.getElementById(hash.replace("#","")).scrollIntoView()
        }
    }, [pathname]);

    return null;
}
